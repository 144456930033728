import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Fees.module.css';

const Fees = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: 3 October, 2024</p>
      <div className={css.scopedList}>
        <ul>
          <h5>BUYERS</h5>
          <li>We do not charge any buyer’s commission.</li>
          <li>If you ship to a location within Washington State, USA, we will collect the mandated sales tax for the shipping address.</li>
          <li>If you live elsewhere, you are responsible for paying your local taxes.</li>
          <li>It is possible we will have to collect sales taxes in the future in other jurisdictions, depending on our growth and on changes in laws.</li>
          <h5>SELLERS</h5>
          <li>We charge a flat 10% of the sale price. We may lower or raise this fee in the future as we observe this market’s workings. We welcome your comments. 
    We will inform all account holders of changes we intend to make at least 30 days in advance.</li>
          <li>We do not charge listing fees at present, and will not before 2026 and possibly never. Whether we institute listing fees will depend on our
    observations of this market’s workings. For example, one thing we want to discourage is for seller to sit indefinitely on overpriced items. Too much of this practice
    would lead to slow turnover, a stagnant marketplace, fewer buyers, and resentment from those who do buy if they later discover they were taken advantage of.</li>
          <li>We do not handle your money, and so we do not take a processing fee. However, at present, our only payment processor is Stripe. They will take a fee for 
    processing credit cards, typically in the amount of 2.9% + 30¢ for US domestic transactions. Fees vary for international purchases.</li>
        </ul>
      </div>
    </div>
  );
};

Fees.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

Fees.propTypes = {
  rootClassName: string,
  className: string,
};

export default Fees;

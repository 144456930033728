import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: 3 October, 2024</p>
      <div className={css.scopedList}>
        <p>
          Thank you for using Platemarked. We honor your privacy — as in, we do not sell or trade your information to anyone.
          The short version of our policy is simple and easy to understand:
        </p>
        <ul>
          <li>We store only what we need for you to use our website and make purchases.</li>
          <li>We do not sell or trade your personal information or use it to advertise to you.</li>
          <li>We do not advertise on Platemarked or enable advertising on Platemarked.</li>
        </ul>
        <p>
          We encourage you to read our complete policy declarations below if you have concerns.
        </p>
        <p>
          Please understand that we rely on third parties for some services. We have no control over their use of your information. Please be aware 
          of <a href="https://stripe.com/privacy">Stripe’s privacy policy</a> and <a href="https://www.sharetribe.com/privacy-policy/">Sharetribe’s privacy policy</a>.
        </p>
        <h2>Policy</h2>
        <p>
          Here are some definitions, just to make it legal:
        </p>
        <ul>
          <li><b>Platemarked</b> means the website doing business as Platemarked, accessible at <code><b>platemarked.com</b></code>.
              It does not include servers and storage provided by third parties to enable Platemarked’s services, nor
              any payment processors, which are provided by independent parties whom we have no control over.</li>
          <li><b>Site</b> (and <b>site</b>) means Platemarked.</li>
          <li><b>We</b> (and <b>we</b>) means Mapthematics LLC, the operators of Platemarked.</li>
          <li><b>You</b> (and <b>you</b>) means means the individual accessing or using Platemarked, or the
              company, or other legal entity on behalf of which such individual is accessing or using Platemarked, as applicable.</li>
        </ul>
        <ul>
          <h5>PRIVACY</h5>
          <li>We do not collect, store, or process your payment vehicles, such as credit card or bank account details. What we keep is the account linkage that
              you registered with one or more independent payment processors, such as Stripe, for the purpose of paying for purchases on Platemarked.</li>
          <li>We store information about you that you provide to us so that we can conduct business with you responsibly. 
              You cannot opt out of this storage.</li>
          <li>We store information about your site usage on your computing device so that Platemarked works properly for you. 
              This storage is called “cookies”. You cannot opt out of cookies.</li>
          <li>We do not sell, exchange, or give away your personally identifiable information for our benefit. However:</li>
          <li>We comply with all laws governing our services. That means we may disclose your information to a governmental agency if required by law or by court order.
              This is the only circumstance in which we disclose your personal information without your explicit authorization.</li>
          <li>You are responsible for your own privacy. If you disclose your identity or any other personal information on Platemarked,
              then you are deemed to have explicitly authorized the disclosure. If you give access to your account to other parties, then you are deemed
              to have authorized their activities on the site. If your personal information has been disclosed on the site accidentally or without your consent,
              please tell us, and we will do what we can to remove it from the site and will require you to change your password. Please understand that we
              cannot control how the information spreads on the Internet after disclosure.</li>
          <li>We may transfer records of your activities on Platemarked in an anonymized form (meaning, there would be no feasible way to connect
              the activities to you) to third-party services. Our reasons for doing this would be to monitor the performance
              and correctness of the services we provide, or to compute and analyze statistics that can help us improve our services.</li>
          <h5>ADVERTISING</h5>
          <li>We do not advertise on Platemarked, neither by promoting any seller’s wares for an extra fee, nor by showing 
              advertisements for wares not sold on Platemarked, nor by using your personal information to recommend products. However, we may inform you of
              Platemarked services.</li>
          <li>The only product “recommendations” we give on the site are search results based on criteria you yourself provide. The order that results get
              returned to you in is not something we deliberately manipulate. We just want you to find what you are looking for.</li>
          <h5>POLICY CHANGES</h5>
          <li>If we decide to change this privacy policy in any material way, we will notify you by means of the contact information that we have
              available, and we will try to contact you more than a month before making the change. If you continue to use Platemarked after the change,
              we will accept your usage as having agreed to the policy change.</li>
        </ul>
      </div>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
